import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React from "react"
// import * as styles from "./DatoProductFilesList.module.scss"

const DatoTechnicalFilesList = loadable(() => import("./productFilesLists/DatoTechnicalFilesList"))

type DatoProductFilesListProps = Queries.DatoProductFilesListFragment

const DatoProductFilesList = (props: DatoProductFilesListProps) => {
  // console.log("Rendering DatoProductFilesList", props)

  switch (props.listType) {
    case "Technical":
      return <DatoTechnicalFilesList />
  }

  return null
}

export default DatoProductFilesList

export const query = graphql`
  fragment DatoProductFilesList on DatoCmsProductFilesList {
    listType
  }
`
